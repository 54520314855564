import React from 'react'
import styled from 'styled-components'

const List = styled.ul`
  list-style: none;
  padding-left: 0;
`

export default () => (
  <table>
    <tbody>
      <tr>
        <td>名称</td>
        <td>UROURO</td>
      </tr>
      <tr>
        <td>事業形態</td>
        <td>フリーランス / 個人事業</td>
      </tr>
      <tr>
        <td>代表</td>
        <td>中井 健太（ナカイ ケンタ）</td>
      </tr>
      <tr>
        <td>所在地</td>
        <td>大阪府大阪市</td>
      </tr>
      <tr>
        <td>E-Mail</td>
        <td>kenta.nakai@urouro.net</td>
      </tr>
      <tr>
        <td>URL</td>
        <td>
          <a href="https://urouro.net">https://urouro.net</a>
        </td>
      </tr>
      <tr>
        <td>事業内容</td>
        <td>
          <List>
            <li>モバイルアプリの企画・開発</li>
            <li>Webアプリケーションの企画・開発</li>
          </List>
        </td>
      </tr>
    </tbody>
  </table>
)
