import React from 'react'
import styled from 'styled-components'

const List = styled.ul`
  padding-left: 1rem;
`

export default () => (
  <>
    <h3>できること</h3>
    <h4>■ iOSアプリの開発</h4>
    <List>
      <li>
        約10年ほどの開発経験があります。Swift, Objective-Cとも問題なく扱えます。
      </li>
      <li>
        開発だけでなく、iOSアプリの申請作業、リリースプロセスのサポート等も可能です。
      </li>
      <li>
        下記の通りバックエンド・フロントエンド技術も扱えるため、アプリと連動するバックエンドの開発や、並行展開するAndroidアプリ・Webアプリの開発を同時に行うことも可能です。
      </li>
    </List>
    <h4>■ Androidアプリの開発</h4>
    <List>
      <li>Kotlinでの開発が可能です。</li>
      <li>軽微なメンテナンスであればAndroid Javaも可能です。</li>
    </List>
    <h4>■ Webシステムのバックエンド開発</h4>
    <List>
      <li>Ruby, PHP, Goでの開発が可能です。</li>
      <li>RubyだとRuby on Railsでの開発経験があります。</li>
      <li>
        PHPだとLaravel, FuelPHP, CakePHP, Slim Frameworkの開発経験があります。
      </li>
      <li>GoだとGin, Echoの開発経験があります。</li>
    </List>
    <h4>■ Webフロントエンド開発</h4>
    <List>
      <li>Vue.js/Nuxt.js, Reactでの開発経験があります。</li>
      <li>ここ数年はTypeScriptを中心に開発することが多いです。</li>
    </List>
    <p>
      その他の言語・環境でも対応可能なケースがございますので、お気軽にご相談ください。
    </p>
    <p>
      ※ ご相談・ご依頼などは{' '}
      <a href="mailto:kenta.nakai@urouro.net">
        <strong>kenta.nakai@urouro.net</strong>
      </a>{' '}
      までお願い致します。
    </p>
  </>
)
