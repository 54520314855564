import React from 'react'
import { Helmet } from 'react-helmet'


const Meta = () => (
  <Helmet>
    <html lang="ja" />
    <title>UROURO</title>
    <meta charset="UTF-8"/>
    <meta name="viewport" content="width=device-width, initial-scale=1.0"/>

    {/* <link rel="stylesheet" href="https://fonts.xz.style/serve/inter.css"/>
    <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/@exampledev/new.css@1/new.min.css"/> */}
  </Helmet>
)

export default Meta
