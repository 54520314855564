import React from 'react'
import { graphql } from 'gatsby'

import '@fortawesome/fontawesome-svg-core/styles.css'
import { config } from '@fortawesome/fontawesome-svg-core'

import Meta from '../components/meta'
import SNSLinks from '../components/SNSLinks'
import Avatar from '../components/Avatar'
import Header from '../components/Header'
import Services from '../components/Services'
import Basic from '../components/Basic'
import Outputs from '../components/Outputs'
import Links from '../components/Links'
import Footer from '../components/Footer'
import Works from '../components/Works'

config.autoAddCss = false

export default ({ data }) => (
  <>
    <Meta />
    <>
      <Header />
      <main>
        <p>
          フリーランスとして、モバイルアプリやWebサービスの開発をしております。
        </p>

        <Avatar fixed={data.me.childImageSharp.fixed} />

        <SNSLinks />

        <Basic />

        {/* <br /> */}
        {/* <Services /> */}

        <br />
        <Outputs />

        <br />
        <Works data={data} />

        <br />
        <Links />
      </main>

      <Footer />
    </>
  </>
)

export const imageQuery = graphql`
  query ImageQuery {
    me: file(relativePath: { eq: "me.jpeg" }) {
      relativePath
      childImageSharp {
        fixed(width: 180) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }

    quotta: file(relativePath: { eq: "quotta.jpg" }) {
      relativePath
      childImageSharp {
        fixed(width: 320, height: 180) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }

    eachangle: file(relativePath: { eq: "eachangle01.png" }) {
      relativePath
      childImageSharp {
        fixed(width: 320, height: 180) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }

    zerokoshi: file(relativePath: { eq: "zerokoshi01.jpg" }) {
      relativePath
      childImageSharp {
        fixed(width: 320, height: 180) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }

    noimage: file(relativePath: { eq: "noimage.jpg" }) {
      relativePath
      childImageSharp {
        fixed(width: 320, height: 180) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
`
