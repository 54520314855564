import React from 'react'
import styled from 'styled-components'

const Container = styled.footer`
  margin-top: 3rem;
  margin-bottom: 0;
`

const Text = styled.p`
  font-size: 1rem;
`

export default () => (
  <Container>
    <Text>© UROURO</Text>
  </Container>
)
