import React from 'react'
import styled from 'styled-components'

const Logo = styled.h1`
  color: #ff7600;
`

export default () => (
  <header>
    <Logo>UROURO</Logo>
    <p>
      Freelance Mobile App Developer
      <br />
      Osaka, Japan
    </p>
  </header>
)
