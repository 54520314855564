import React from 'react'
import styled from 'styled-components'

export default () => (
  <>
    <h3>アウトプット</h3>
    {/* <h4>Pick Up</h4> */}
    <ul>
      <li>
        <a
          href="https://speakerdeck.com/urouro/putusiyutong-zhi-hadofalseyounajin-hua-wotadotutekitaka"
          target="_blank"
        >
          プッシュ通知はどのような進化をたどってきたか (iOSDC Japan 2018 登壇)
        </a>
      </li>
      <li>
        <a
          href="https://speakerdeck.com/urouro/asset-catalog-reintroduction"
          target="_blank"
        >
          Asset Catalog再入門 (Mobile Act OSAKA #7)
        </a>
      </li>
      <li>
        <a
          href="https://speakerdeck.com/urouro/kuratusiyurepotosabisufalsepankuzuji-neng-dekuratusiyudui-ying-wole-nisuru"
          target="_blank"
        >
          クラッシュレポートサービスのパンくず機能でクラッシュ対応を楽にする (Mobile Act OSAKA #5)
        </a>
      </li>
    </ul>
    {/* <h4>その他</h4>
    <ul>
      <li><a href="https://speakerdeck.com/urouro" target="_blank">SpeakerDeck</a></li>
      <li><a href="https://qiita.com/urouro_net" target="_blank">Qiita</a></li>
      <li><a href="https://blog.urouro.net/" target="_blank">ブログ</a></li>
    </ul> */}
  </>
)
