import React from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'

const Container = styled.div`
  width: 180px;
  margin-bottom: 16px;
`

const Image = styled(Img)`
  border-radius: 10px;
`

export default ({ fixed }) => {
  return (
    <Container>
      <Image fixed={fixed} alt="" />
    </Container>
  )
}
