import React from 'react'
import styled from 'styled-components'

export default () => (
  <>
    <h3>リンク</h3>
    <ul>
      <li>
        <a href="https://twitter.com/urouro_net">Twitter</a>
      </li>
      <li>
        <a href="https://www.facebook.com/kenta.nakai.144">Facebook</a>
      </li>
      <li>
        <a href="https://github.com/urouro-net">GitHub</a>
      </li>
      <li>
        <a href="https://speakerdeck.com/urouro_net">SpeakerDeck</a>
      </li>
      <li>
        <a href="https://zenn.dev/urouro_net?tab=scraps">Zenn</a>
      </li>
      <li>
        <a href="https://qiita.com/urouro_net">Qiita</a>
      </li>
      <li>
        <a href="https://urouro.hatenadiary.jp/">ブログ</a>
      </li>
    </ul>
  </>
)
