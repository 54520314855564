import React from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faTwitter,
  faFacebookSquare,
  faGithub,
} from '@fortawesome/free-brands-svg-icons'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'

const SNSList = styled.ul`
  list-style: none;
  padding-left: 0;
`

const SNSListItem = styled.li`
  display: inline-block;
`

const SNSListLink = styled.a`
  width: 32px;
  height: 32px;
  font-size: 24px;
  margin-left: 8px;
  margin-right: 8px;
  cursor: pointer;

  @media (prefers-color-scheme: dark) {
    color: white;
  }
`

export default () => (
  <SNSList>
    <SNSListItem>
      <SNSListLink href={'https://twitter.com/urouro_net'} target={'_blank'}>
        <FontAwesomeIcon icon={faTwitter} />
      </SNSListLink>
    </SNSListItem>
    <SNSListItem>
      <SNSListLink href={'https://www.facebook.com/kenta.nakai.144'} target={'_blank'}>
        <FontAwesomeIcon icon={faFacebookSquare} />
      </SNSListLink>
    </SNSListItem>
    <SNSListItem>
      <SNSListLink href={'https://github.com/urouro-net'} target={'_blank'}>
        <FontAwesomeIcon icon={faGithub} />
      </SNSListLink>
    </SNSListItem>
    <SNSListItem>
      <SNSListLink href={'mailto:kenta.nakai@urouro.net'} target={'_blank'}>
        <FontAwesomeIcon icon={faEnvelope} />
      </SNSListLink>
    </SNSListItem>
  </SNSList>
)
