import React from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'

const Container = styled.ul`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  list-style: none;
  padding-left: 0;
  margin-bottom: 0.5rem;

  & > li {
    margin: 8px;
  }
`

const Card = styled.div`
  max-width: calc((750px / 2) - (8px * 6));
  overflow: hidden;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1);
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  height: 100%;

  @media (prefers-color-scheme: light) {
    background-color: white;
    border-color: #ccc;
    color: black;
  }

  @media (prefers-color-scheme: dark) {
    background-color: #424242;
    border-color: #333;
    color: #fff;
  }
`

const CardContent = styled.div`
  padding: 16px;
`

const CardImage = styled(Img)`
  margin-bottom: 0;
`

const CardTitle = styled.p`
  font-size: 1.25rem;
  font-weight: bold;
`

const CardCaption = styled.p`
  font-size: 1rem;
  margin-bottom: 0;
`

const CardLinks = styled.ul`
  margin-top: 1rem;
  padding-left: 0;
  list-style: none;
`

const CardLink = styled.li``

const Notice = styled.p`
  font-size: 0.8rem;
`

const works = [
  {
    id: 'quotta',
    title: '貸し借りアプリ「クオッタ」',
    caption: 'iOSアプリ・サーバサイドAPI/管理画面・フロントエンド開発・インフラ整備等々全般的に担当させて頂きました。',
    links: [
      {
        title: 'ホームページ',
        url: 'https://quotta.net/',
      },
      {
        title: 'iOS App',
        url: 'https://apps.apple.com/jp/app/id1288431440',
      }
    ],
  },
  {
    id: 'transport-ticket',
    title: '交通機関チケット購入アプリ',
    caption: 'iOS/Androidアプリ・サーバサイド・フロントエンドの開発を担当させて頂きました。',
    links: [],
  },
  {
    id: 'eachangle',
    title: '画像共有アプリ「Each Angle」',
    caption:
      'iOSアプリの開発、サーバサイドAPIの開発、インフラの構築、申請等々開発全般を担当させて頂きました。<br />※ 現在はサービス終了しています。',
    links: [],
  },
  {
    id: 'chintai_mall',
    title: '賃貸モール iOS/Android アプリ',
    caption:
      'ハウスセゾン様の運営するサービス「賃貸モール」のiOS/Androidアプリ開発を担当させて頂きました。（公開終了）<br/>アプリに必要なWeb API・管理画面も含めて併せて開発させて頂きました。',
    links: [
      {
        title: '開発実績紹介（ブログ）',
        url: 'https://urouro.hatenadiary.jp/entry/2015/07/10/144107',
      },
    ],
  },
]

export default ({ data }) => (
  <>
    <h3>開発実績</h3>
    <Container>
      {works.map(work => {
        let imageFixed
        if (data[work.id]) {
          imageFixed = data[work.id].childImageSharp.fixed
        } else {
          imageFixed = data.noimage.childImageSharp.fixed
        }
        return (
          <li key={work.id}>
            <Card>
              <CardImage
                fixed={imageFixed}
                objectFit="cover"
                alt=""
                style={{ width: '100%' }}
              />
              <CardContent>
                <CardTitle>{work.title}</CardTitle>
                <CardCaption dangerouslySetInnerHTML={{ __html: work.caption }} />
                <CardLinks>
                  {work.links.map(link => (
                    <CardLink>
                      <a href={link.url} target="_blank">
                        {link.title}
                      </a>
                    </CardLink>
                  ))}
                </CardLinks>
              </CardContent>
            </Card>
          </li>
        )
      })}
    </Container>
    <Notice>※&nbsp;非公開の開発実績も多数ございます。</Notice>
  </>
)
